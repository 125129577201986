import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import exportFromJSON from 'export-from-json';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import StatusHighliter from '../../../components/StatusHighliter/StatusHighliter';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  CreateButton,
  MessageBox,
  CustomButton,
  ResetButton,
  Button,
  Dropdown,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import {
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
  StyledMicroButton,
  StyledModalContainer,
  StyledFiltersContainer,
  StyledRechargeCountsContainer,
  StyledRechargeCounter,
  StyledHeader,
} from '../../../components/Styled';
import { Image } from '../../../components/Image';
import Modal from '../../../components/Modal';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import {
  ACCOUNT_TYPES,
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
} from '../../../constants';
import {
  getSearchParams,
  camelCaseToString,
  authData,
  formatCurrency,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';

const StyledTabsContainer = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  padding: 0px;
  margin: 0px 0px 20px 0px;
  border-radius: 5px;
`;

const StyledTabButton = styled(Button)`
  width: 100%;
  border-radius: 0px;
  padding: 12.5px;
  &:first-child {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  &:last-child {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  &.active {
    background-color: #003f6c;
  }
`;

const WalletDepositRequestsTable = (props) => {
  const { readWalletBalance } = props;
  const { accountType } = authData.get();
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [walletDepositRequests, setWalletDepositRequests] = useState([]);
  const [tab, setTab] = useState(
    [
      ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
      ACCOUNT_TYPES.DISTRIBUTORS,
      ACCOUNT_TYPES.RETAILERS,
      ACCOUNT_TYPES.API_USERS,
    ].includes(accountType)
      ? 'raisedByMe'
      : 'raisedToMe'
  );
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [walletDepositRequestsId, setWalletDepositRequestsId] = useState('');
  const [remark, setRemark] = useState('');
  const [accounts, setAccounts] = useState([]);

  const [status, setStatus] = useState('All');
  const [rolesId, setRolesId] = useState('');
  const [accountsId, setAccountsId] = useState('');

  const [processed, setProcessed] = useState('');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [counts, setCounts] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setRemark('');
  };

  const readCounts = () => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/accounting/wallet-deposit-requests/read-counts/?tab=${tab}`)
      .then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setCounts(data[0]);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const clearFilters = () => {
    setStatus('All');
    setRolesId('');
    setAccountsId('');
    setProcessed('');
    setStartDate('');
    setEndDate('');
  };

  const navigateTab = (newTab) => {
    clearFilters();
    setTab(newTab);
  };

  const resetFilters = () => {
    clearFilters();
    readData('Pending', accountsId, startDate, endDate);
  };

  const readAccounts = () => {
    API.get('/accounts-management/accounts').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setAccounts(data);
      }
    });
  };

  const readData = useCallback(
    (
      status = 'Pending',
      accountsId = '',
      processed = '',
      startDate = '',
      endDate = ''
    ) => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/accounting/wallet-deposit-requests/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fType=${tab}&fStatus=${status}&fAccountsId=${accountsId}&fProcessed=${processed}&fStartDate=${startDate}&fEndDate=${endDate}`
      )
        .then((response) => {
          const { status, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setTotalRecords(pageInfo.totalRecords);
            }
            setWalletDepositRequests(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData, tab]
  );

  const onSubmit = (e) => {
    const walletDepositRequest = walletDepositRequests.find(
      (item) => item.id === walletDepositRequestsId
    );
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    let formData = {};
    if (modalType === 'Approve') {
      formData = {
        ...walletDepositRequest,
        approvalStatus: 'Approved',
        remark,
      };
    } else {
      formData = {
        ...walletDepositRequest,
        approvalStatus: 'Rejected',
        remark,
      };
    }
    API.put(
      `/accounting/wallet-deposit-requests/${walletDepositRequestsId}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fType=${tab}&fStatus=${status}&fAccountsId=${accountsId}&fProcessed=${processed}&fStartDate=${startDate}&fEndDate=${endDate}`,
      formData
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            if (
              [
                ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
                ACCOUNT_TYPES.DISTRIBUTORS,
              ].includes(accountType) &&
              modalType !== PERMISSION_TYPES.CHANGE_PASSWORD
            ) {
              readWalletBalance();
            }
            resetForm();
            setWalletDepositRequests(data);
            setTimeout(() => {
              resetResponseData();
              setWalletDepositRequestsId('');
              setModalType('');
              setShowModal(false);
            }, 3000);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const markAsCollected = (id) => {
    const walletDepositRequest = walletDepositRequests.find(
      (item) => item.id === id
    );
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(
      `/accounting/wallet-deposit-requests/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fType=${tab}&fStatus=${status}&fAccountsId=${accountsId}&fProcessed=${processed}&fStartDate=${startDate}&fEndDate=${endDate}`,
      { ...walletDepositRequest, creditStatus: 'Collected' }
    )
      .then((response) => {
        const { status, message, data, pageInfo } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setWalletDepositRequests(data);
            setTotalRecords(pageInfo.totalRecords);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadData = () => {
    readData(status, accountsId, processed, startDate, endDate);
    readCounts();
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  const onClickExport = () => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/accounting/wallet-deposit-requests/export/?fType=${tab}&fStatus=${status}&fAccountsId=${accountsId}&fProcessed=${processed}&fStartDate=${startDate}&fEndDate=${endDate}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          exportFromJSON({
            data,
            fileName: 'wallet_deposit_requests',
            exportType: exportFromJSON.types.csv,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (
      accountType !== ACCOUNT_TYPES.RETAILERS &&
      accountType !== ACCOUNT_TYPES.API_USERS
    ) {
      readAccounts();
    }
  }, [accountType]);

  useEffect(() => {
    if (!isFiltering) {
      readData(status, accountsId, processed, startDate, endDate);
    }
  }, [paginationData, tab, readData]);

  useEffect(() => {
    readData(status, accountsId, processed, startDate, endDate);
  }, []);

  useEffect(() => {
    readCounts();
  }, []);

  useEffect(() => {
    readCounts();
  }, [tab]);

  const { accountsPermissions } = props;
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canExport =
    accountsPermissions.includes(PERMISSION_TYPES.EXPORT) || false;

  let columns = [];
  let tableFooter = [];

  if (tab === 'raisedByMe') {
    columns = [
      {
        title: 'Requested To',
        dataSelector: 'toAccountsId',
        dataType: 'string',
        CellRenderer: (value, item) => {
          const { firstName, lastName, isEntity, entityName, roleName, photo } =
            item;
          return value === 0 ? (
            'Admin'
          ) : (
            <StyledIconTitleContainer>
              <StyledIconContainer>
                <Image
                  source={photo ? photo : '/no-image.png'}
                  alt={
                    isEntity === 'Yes' ? entityName : `${firstName} ${lastName}`
                  }
                  style={{ width: '50px', height: '50px' }}
                />
              </StyledIconContainer>
              <StyledTitleContainer>
                {isEntity === 'Yes' ? entityName : `${firstName} ${lastName}`}
                <StyledSpan>{camelCaseToString(roleName)}</StyledSpan>
              </StyledTitleContainer>
            </StyledIconTitleContainer>
          );
        },
      },
      {
        title: 'Deposit Details',
        dataSelector: 'depositRequestType',
        dataType: 'string',
        CellRenderer: (value, item) => {
          const { bankName, bankAccountNumberOrUpi } = item;
          return (
            <>
              {value}
              {bankName && <StyledSpan>{bankName}</StyledSpan>}
              {bankAccountNumberOrUpi && (
                <StyledSpan>{bankAccountNumberOrUpi}</StyledSpan>
              )}
            </>
          );
        },
      },
      {
        title: 'UTR / Reference Number',
        dataSelector: 'transactionsUTRNumber',
        dataType: 'string',
        CellRenderer: (value) => (value ? value : 'N/A'),
      },
      {
        title: 'Amount',
        dataSelector: 'amount',
        dataType: 'number',
        align: 'center',
        CellRenderer: (value) => formatCurrency(value),
      },
      {
        title: 'Remark',
        dataSelector: 'toAccountsRemark',
        dataType: 'string',
        CellRenderer: (value) => (value ? value : 'N/A'),
      },
      {
        title: 'Status',
        dataSelector: 'approvalStatus',
        dataType: 'string',
        align: 'center',
        CellRenderer: (value) =>
          value && (
            <StatusHighliter
              className={
                value === 'Approved'
                  ? 'green'
                  : value === 'Rejected'
                  ? 'red'
                  : 'yellow'
              }
            >
              {value}
            </StatusHighliter>
          ),
      },
      {
        title: 'Transactions Date',
        dataSelector: 'transactionsDate',
        dataType: 'date',
        CellRenderer: (value) => (value ? value : 'N/A'),
      },
      {
        title: 'Processed Date',
        dataSelector: 'approvalDate',
        dataType: 'date',
        CellRenderer: (value) => (value ? value : 'N/A'),
      },
    ];
  } else if (tab === 'raisedToMe') {
    columns = [
      {
        title: '',
        dataSelector: 'id',
        sticky: true,
        canSort: false,
        width: '100px',
        CellRenderer: (value, item) =>
          canUpdate &&
          item.approvalStatus === 'Pending' && (
            <>
              <CustomButton
                icon={faCircleCheck}
                onClick={() => {
                  setWalletDepositRequestsId(value);
                  setModalType('Approve');
                  setShowModal(true);
                }}
              />
              <CustomButton
                icon={faCircleXmark}
                onClick={() => {
                  setWalletDepositRequestsId(value);
                  setModalType('Reject');
                  setShowModal(true);
                }}
                style={{ color: 'red' }}
              />
            </>
          ),
      },
      {
        title: 'Requested By',
        dataSelector: 'fromAccountsId',
        dataType: 'string',
        CellRenderer: (value, item) => {
          const { firstName, lastName, isEntity, entityName, roleName, photo } =
            item;
          return (
            value && (
              <StyledIconTitleContainer>
                <StyledIconContainer>
                  <Image
                    source={photo ? photo : '/no-image.png'}
                    alt={
                      isEntity === 'Yes'
                        ? entityName
                        : `${firstName} ${lastName}`
                    }
                    style={{ width: '50px', height: '50px' }}
                  />
                </StyledIconContainer>
                <StyledTitleContainer>
                  {isEntity === 'Yes' ? entityName : `${firstName} ${lastName}`}
                  <StyledSpan>{camelCaseToString(roleName)}</StyledSpan>
                </StyledTitleContainer>
              </StyledIconTitleContainer>
            )
          );
        },
      },
      {
        title: 'Deposit Details',
        dataSelector: 'depositRequestType',
        dataType: 'string',
        CellRenderer: (value, item) => {
          const { bankName, bankAccountNumberOrUpi } = item;
          return (
            <>
              {value}
              {bankName && <StyledSpan>{bankName}</StyledSpan>}
              {bankAccountNumberOrUpi && (
                <StyledSpan>{bankAccountNumberOrUpi}</StyledSpan>
              )}
            </>
          );
        },
      },
      {
        title: 'UTR / Reference Number',
        dataSelector: 'transactionsUTRNumber',
        dataType: 'string',
        CellRenderer: (value) => (value ? value : 'N/A'),
      },
      {
        title: 'Amount',
        dataSelector: 'amount',
        dataType: 'number',
        align: 'center',
        CellRenderer: (value) => formatCurrency(value),
      },
      {
        title: 'Remark',
        dataSelector: 'fromAccountsRemark',
        dataType: 'string',
        CellRenderer: (value) => (value ? value : 'N/A'),
      },
      {
        title: 'Status',
        dataSelector: 'approvalStatus',
        dataType: 'string',
        align: 'center',
        CellRenderer: (value) =>
          value && (
            <StatusHighliter
              className={
                value === 'Approved'
                  ? 'green'
                  : value === 'Rejected'
                  ? 'red'
                  : 'yellow'
              }
            >
              {value}
            </StatusHighliter>
          ),
      },
      {
        title: 'Transactions Date',
        dataSelector: 'transactionsDate',
        dataType: 'date',
        CellRenderer: (value) => (value ? value : 'N/A'),
      },
      {
        title: 'Processed Date',
        dataSelector: 'approvalDate',
        dataType: 'date',
        CellRenderer: (value) => (value ? value : 'N/A'),
      },
    ];
  } else if (tab === 'manualProcessByMe') {
    columns = [
      {
        title: 'Processed To',
        dataSelector: 'fromAccountsId',
        dataType: 'string',
        CellRenderer: (value, item) => {
          const { firstName, lastName, isEntity, entityName, roleName, photo } =
            item;
          return (
            <StyledIconTitleContainer>
              <StyledIconContainer>
                <Image
                  source={photo ? photo : '/no-image.png'}
                  alt={
                    isEntity === 'Yes' ? entityName : `${firstName} ${lastName}`
                  }
                  style={{ width: '50px', height: '50px' }}
                />
              </StyledIconContainer>
              <StyledTitleContainer>
                {isEntity === 'Yes' ? entityName : `${firstName} ${lastName}`}
                <StyledSpan>{camelCaseToString(roleName)}</StyledSpan>
              </StyledTitleContainer>
            </StyledIconTitleContainer>
          );
        },
      },
      {
        title: 'Transaction Type',
        dataSelector: 'transactionType',
        dataType: 'string',
      },
      {
        title: 'Amount',
        dataSelector: 'amount',
        dataType: 'number',
        align: 'center',
        CellRenderer: (value) => formatCurrency(value),
      },
      {
        title: 'Status',
        dataSelector: 'approvalStatus',
        dataType: 'string',
        align: 'center',
        CellRenderer: (value) =>
          value && (
            <StatusHighliter
              className={
                value === 'Approved'
                  ? 'green'
                  : value === 'Rejected'
                  ? 'red'
                  : 'yellow'
              }
            >
              {value}
            </StatusHighliter>
          ),
      },
      {
        title: 'Is Credit',
        dataSelector: 'isCredit',
        dataType: 'string',
        align: 'center',
        CellRenderer: (value) =>
          value && (
            <StatusHighliter className={value === 'Yes' ? 'green' : 'red'}>
              {value}
            </StatusHighliter>
          ),
      },
      {
        title: 'Credit Status',
        dataSelector: 'creditStatus',
        dataType: 'string',
        align: 'center',
        CellRenderer: (value, item) => {
          return value ? (
            value === 'Collected' ? (
              value
            ) : (
              <>
                {value}
                <StyledMicroButton
                  style={{ display: 'block', margin: '5px auto 0px' }}
                  onClick={() => markAsCollected(item.id)}
                >
                  Mark Collected
                </StyledMicroButton>
              </>
            )
          ) : (
            'N/A'
          );
        },
      },
      {
        title: 'Transaction Date',
        dataSelector: 'createdOn',
        dataType: 'date',
      },
    ];
  } else if (tab === 'manualProcessToMe') {
    columns = [
      {
        title: 'Processed By',
        dataSelector: 'toAccountsId',
        dataType: 'string',
        CellRenderer: (value, item) => {
          const { firstName, lastName, isEntity, entityName, roleName, photo } =
            item;
          return value === 0 ? (
            'Admin'
          ) : (
            <StyledIconTitleContainer>
              <StyledIconContainer>
                <Image
                  source={photo ? photo : '/no-image.png'}
                  alt={
                    isEntity === 'Yes' ? entityName : `${firstName} ${lastName}`
                  }
                  style={{ width: '50px', height: '50px' }}
                />
              </StyledIconContainer>
              <StyledTitleContainer>
                {isEntity === 'Yes' ? entityName : `${firstName} ${lastName}`}
                <StyledSpan>{camelCaseToString(roleName)}</StyledSpan>
              </StyledTitleContainer>
            </StyledIconTitleContainer>
          );
        },
      },
      {
        title: 'Transaction Type',
        dataSelector: 'transactionType',
        dataType: 'string',
      },
      {
        title: 'Amount',
        dataSelector: 'amount',
        dataType: 'number',
        align: 'center',
        CellRenderer: (value) => formatCurrency(value),
      },
      {
        title: 'Status',
        dataSelector: 'approvalStatus',
        dataType: 'string',
        align: 'center',
        CellRenderer: (value) =>
          value && (
            <StatusHighliter
              className={
                value === 'Approved'
                  ? 'green'
                  : value === 'Rejected'
                  ? 'red'
                  : 'yellow'
              }
            >
              {value}
            </StatusHighliter>
          ),
      },
      {
        title: 'Is Credit',
        dataSelector: 'isCredit',
        dataType: 'string',
        align: 'center',
        CellRenderer: (value) =>
          value && (
            <StatusHighliter className={value === 'Yes' ? 'green' : 'red'}>
              {value}
            </StatusHighliter>
          ),
      },
      {
        title: 'Credit Status',
        dataSelector: 'creditStatus',
        dataType: 'string',
        align: 'center',
        CellRenderer: (value) => {
          return value ? value : 'N/A';
        },
      },
      {
        title: 'Transaction Date',
        dataSelector: 'createdOn',
        dataType: 'date',
      },
    ];
  }

  if (tab === 'raisedByMe') {
    tableFooter = [
      {
        value: '',
      },
      {
        value: '',
      },
      {
        align: 'center',
        value: 'Total Approved Amount',
      },
      {
        align: 'center',
        value: formatCurrency(
          walletDepositRequests.reduce(
            (total, walletDepositRequest) =>
              walletDepositRequest.approvalStatus === 'Approved'
                ? parseFloat(walletDepositRequest.amount) + total
                : total,
            0
          )
        ),
      },
      {
        value: '',
      },
      {
        value: '',
      },
      {
        value: '',
      },
      {
        value: '',
      },
    ];
  } else if (tab === 'raisedToMe') {
    tableFooter = [
      {
        value: '',
      },
      {
        value: '',
      },
      {
        value: '',
      },
      {
        align: 'center',
        value: 'Total Approved Amount',
      },
      {
        align: 'center',
        value: formatCurrency(
          walletDepositRequests.reduce(
            (total, walletDepositRequest) =>
              walletDepositRequest.approvalStatus === 'Approved'
                ? parseFloat(walletDepositRequest.amount) + total
                : total,
            0
          )
        ),
      },
      {
        value: '',
      },
      {
        value: '',
      },
      {
        value: '',
      },
      {
        value: '',
      },
    ];
  } else if (tab === 'manualProcessByMe') {
    tableFooter = [
      {
        value: '',
      },
      {
        align: 'center',
        value: 'Total Approved Amount',
      },
      {
        align: 'center',
        value: formatCurrency(
          walletDepositRequests.reduce(
            (total, walletDepositRequest) =>
              walletDepositRequest.approvalStatus === 'Approved'
                ? parseFloat(walletDepositRequest.amount) + total
                : total,
            0
          )
        ),
      },
      {
        value: '',
      },
      {
        value: '',
      },
      {
        value: '',
      },
      {
        value: '',
      },
    ];
  } else if (tab === 'manualProcessToMe') {
    tableFooter = [
      {
        value: '',
      },
      {
        align: 'center',
        value: 'Total Approved Amount',
      },
      {
        align: 'center',
        value: formatCurrency(
          walletDepositRequests.reduce(
            (total, walletDepositRequest) =>
              walletDepositRequest.approvalStatus === 'Approved'
                ? parseFloat(walletDepositRequest.amount) + total
                : total,
            0
          )
        ),
      },
      {
        value: '',
      },
      {
        value: '',
      },
      {
        value: '',
      },
      {
        value: '',
      },
    ];
  }

  const rolesOptions = [{ title: '-- SELECT ROLE --', value: '' }];
  if (accountType === ACCOUNT_TYPES.MASTER_DISTRIBUTORS) {
    [
      {
        id: 3,
        roleName: 'Distributors',
      },
      {
        id: 4,
        roleName: 'Retailers',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else if (accountType === ACCOUNT_TYPES.DISTRIBUTORS) {
    [
      {
        id: 4,
        roleName: 'Retailers',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else {
    [
      {
        id: 2,
        roleName: 'Master Distributors',
      },
      {
        id: 3,
        roleName: 'Distributors',
      },
      {
        id: 4,
        roleName: 'Retailers',
      },
      {
        id: 5,
        roleName: 'API Users',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  }

  const accountsOptions = [{ title: '-- SELECT ACCOUNT --', value: '' }];
  accounts
    .filter((account) => account?.isDeleted === 'No')
    .filter(({ accountsRolesId }) =>
      rolesId !== ''
        ? accountsRolesId === rolesId
        : [2, 3, 4, 5].includes(accountsRolesId)
    )
    .forEach(({ id, firstName, lastName }) =>
      accountsOptions.push({
        title: `${firstName} ${lastName}`,
        value: id,
      })
    );

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading
        {...props}
        reloadData={reloadData}
        showExport={canExport}
        onClickExport={onClickExport}
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          {(tab === 'raisedByMe' || tab === 'raisedToMe') && (
            <Fieldset>
              <Label>Status</Label>
              <Dropdown
                style={{ border: '1px solid #cacaca' }}
                placeholder='Status'
                value={status}
                onChange={setStatus}
                options={[
                  {
                    title: '-- SELECT STATUS --',
                    value: '',
                  },
                  {
                    title: 'All',
                    value: 'All',
                  },
                  {
                    title: 'Pending',
                    value: 'Pending',
                  },
                  {
                    title: 'Approved',
                    value: 'Approved',
                  },
                  {
                    title: 'Rejected',
                    value: 'Rejected',
                  },
                ]}
                disabled={isLoading}
              />
            </Fieldset>
          )}
          {(tab === 'raisedByMe' || tab === 'manualProcessToMe') && (
            <Fieldset>
              <Label>Process</Label>
              <Dropdown
                style={{ border: '1px solid #cacaca' }}
                placeholder={
                  tab === 'raisedByMe' ? 'Requested To' : 'Processed By'
                }
                value={processed}
                onChange={setProcessed}
                options={[
                  {
                    title:
                      tab === 'raisedByMe'
                        ? '-- SELECT REQUESTED TO --'
                        : '-- SELECT PROCESSED BY --',
                    value: '',
                  },
                  {
                    title: 'Admin',
                    value: 'Admin',
                  },
                  {
                    title: 'Supervisor',
                    value: 'Supervisor',
                  },
                ]}
                disabled={isLoading}
              />
            </Fieldset>
          )}
          {(tab === 'raisedToMe' || tab === 'manualProcessByMe') && (
            <>
              <Fieldset>
                <Label>Role</Label>
                <Dropdown
                  style={{ border: '1px solid #cacaca' }}
                  placeholder='Role'
                  value={rolesId}
                  onChange={(value) => {
                    setAccountsId('');
                    setRolesId(value);
                  }}
                  options={rolesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset>
                <Label>Account</Label>
                <Dropdown
                  style={{ border: '1px solid #cacaca' }}
                  placeholder='Account'
                  value={accountsId}
                  onChange={setAccountsId}
                  options={accountsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </>
          )}
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type='date'
              value={startDate}
              onChange={setStartDate}
              placeholder='Start Date'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type='date'
              value={endDate}
              onChange={setEndDate}
              placeholder='End Date'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetFilters}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <StyledTabsContainer>
        {[
          ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
          ACCOUNT_TYPES.DISTRIBUTORS,
          ACCOUNT_TYPES.RETAILERS,
          ACCOUNT_TYPES.API_USERS,
        ].includes(accountType) && (
          <StyledTabButton
            onClick={() => navigateTab('raisedByMe')}
            className={tab === 'raisedByMe' ? 'active' : ''}
          >
            Requested By Me
          </StyledTabButton>
        )}
        {![ACCOUNT_TYPES.RETAILERS, ACCOUNT_TYPES.API_USERS].includes(
          accountType
        ) && (
          <>
            <StyledTabButton
              onClick={() => navigateTab('raisedToMe')}
              className={tab === 'raisedToMe' ? 'active' : ''}
            >
              Requested to Me
            </StyledTabButton>
            <StyledTabButton
              onClick={() => navigateTab('manualProcessByMe')}
              className={tab === 'manualProcessByMe' ? 'active' : ''}
            >
              Fund Transfered by Me
            </StyledTabButton>
          </>
        )}
        {[
          ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
          ACCOUNT_TYPES.DISTRIBUTORS,
          ACCOUNT_TYPES.RETAILERS,
          ACCOUNT_TYPES.API_USERS,
        ].includes(accountType) && (
          <StyledTabButton
            onClick={() => navigateTab('manualProcessToMe')}
            className={tab === 'manualProcessToMe' ? 'active' : ''}
          >
            Fund Transfered To Me
          </StyledTabButton>
        )}
      </StyledTabsContainer>
      <StyledRechargeCountsContainer>
        {counts.successAmount && (
          <StyledRechargeCounter color={'forestgreen'}>
            <StyledHeader>{`${formatCurrency(counts.successAmount)} ( ${
              counts.successCount
            } )`}</StyledHeader>
          </StyledRechargeCounter>
        )}
        {counts.failureAmount && (
          <StyledRechargeCounter color={'red'}>
            <StyledHeader>{`${formatCurrency(counts.failureAmount)} ( ${
              counts.failureCount
            } )`}</StyledHeader>
          </StyledRechargeCounter>
        )}
        {counts.pendingAmount && (
          <StyledRechargeCounter color={'#ff9800'}>
            <StyledHeader>{`${formatCurrency(counts.pendingAmount)} ( ${
              counts.pendingCount
            } )`}</StyledHeader>
          </StyledRechargeCounter>
        )}
      </StyledRechargeCountsContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === '' &&
          !showModal &&
          walletDepositRequests?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          walletDepositRequests?.length === 0
        }
        message={`No wallet deposit requests found`}
      />
      {!showModal && responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {walletDepositRequests?.length > 0 && (
        <>
          <TableBuilder
            isLoading={
              !showModal && walletDepositRequests?.length !== 0 && isLoading
            }
            tableHeadings={columns}
            tableData={walletDepositRequests}
            tableFooter={tableFooter}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              resetForm();
              setModalType('');
              setShowModal(false);
            }}
            hideCloseButton
          >
            <StyledModalContainer width='500px'>
              <h3>
                {modalType === 'Approve' ? 'Approve Request' : 'Reject Request'}
              </h3>
              <FormContainer>
                <Form method='POST' action='#' onSubmit={onSubmit}>
                  <Fieldset>
                    <Label required={modalType === 'Reject'}>Remark</Label>
                    <TextInput
                      value={remark}
                      onChange={setRemark}
                      placeholder='Please enter remark'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                  {showModal && responseStatus !== '' && (
                    <Fieldset>
                      <MessageBox
                        status={responseStatus}
                        message={responseMessage}
                      />
                    </Fieldset>
                  )}
                  <Fieldset>
                    <CreateButton
                      disabled={isLoading === true}
                      type='submit'
                      style={{ width: 'auto' }}
                    >
                      {modalType === 'Approve' ? `Approve` : `Reject`}
                    </CreateButton>
                    <ResetButton
                      disabled={isLoading === true}
                      type='button'
                      onClick={resetForm}
                    >
                      Reset
                    </ResetButton>
                  </Fieldset>
                  <OverlayLoader showLoader={showModal && isLoading} />
                </Form>
              </FormContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(WalletDepositRequestsTable);
